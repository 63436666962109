import {AppBar, Container, Typography, Box} from "@mui/material";
import React, {FC, useEffect, useState} from "react";
import {get_gym} from "./api";

export interface topbarInput {
    uid: number,
    gym: string
}

const TopBar: FC<topbarInput> = ({uid, gym}) => {
    const [logo, setLogo] = useState("")
    const [color, setColor] = useState("")
    useEffect(() => {
        get_gym(gym).then((details) => {
            setLogo(details?.logo);
            setColor(details?.color||"");

        });
    }, [gym]);
    return (
        <AppBar position="static" style={{
            height: '130px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: color
        }}>
            <img src={logo} alt="logo" style={{height: '100%', position: 'relative'}}/>
            <Container maxWidth="xl" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <div className="floating-qr-code">
                    {uid}
                </div>
            </Container>
        </AppBar>
    );
}
export default TopBar;
