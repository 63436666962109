import React, {useEffect, useRef, useState} from 'react';
import './App.css';
import StackGrid, {easings, Grid, transitions} from "react-stack-grid";
import WorkoutCard from "./WorkoutCard";
import TopBar from "./TopBar";
import {get_cards} from "./api";
// import {faker} from '@faker-js/faker';
// import QRCode from 'qrcode.react';
// import uuid from 'react-uuid'

interface StackConfig {
    items: any[];
    duration: number;
    columnWidth: number;
    gutter: number;
    easing: any;
    transition: string;
    rtl: boolean;
}

function App() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [cardWidth, setCardWidth] = useState(250);
    const [cardData, setCardData] = useState<any>([])
    const [gridRef, setGridRef] = useState<Grid>()
    const [uid, setUid] = useState(-1);
    const [company, setCompany] = useState("");
    const intervalRef = useRef<NodeJS.Timeout | null>(null); // Added this line
    let [data, setData] = useState<StackConfig>({
        items: cardData,
        duration: 480,
        // @ts-ignore
        columnWidth: cardWidth,
        gutter: 10,
        easing: easings.quartOut,
        transition: 'fadeDown',
        rtl: false
    });
    // let itemsLength = 50;
    const uuidFromReactUUID = () => {
        try {
            let path = window.location.pathname.split("/")
            if (path.length == 3) {
                setCompany(path[1])
                if (path[2].length == 5) {
                    let uid = parseInt(path[2])
                    setUid(uid)
                    return
                } else {
                    console.log("path too short")
                }
            } else if (path.length == 2) {
                let newUuid = Math.floor(10000 + Math.random() * 90000);
                setUid(newUuid)
                setCompany(path[1])
                console.log(`/${path[1]}/${newUuid}`)
                window.location.pathname = `/${path[1]}/${newUuid}`;
            }
        } catch (e) {
        }
    }
    useEffect(() => {
        intervalRef.current = setInterval(async () => {
            let data = await refreshData()
            setCardData(data);
            // resetData()
        }, 1000)
        return () => {
            if (intervalRef.current !== null) {
                clearInterval(intervalRef.current);
            }
        }
    }, [uid]);

    async function refreshData() {
        if (uid !== -1) {
            // @ts-ignore
            let items = []
            let card_items = await get_cards(company, uid);
            for (let i = 0; i < card_items.length; i++) {
                if (card_items[i].lastData) {
                    items.push({
                        id: i,
                        height: cardWidth,
                        name: card_items[i].name[0],
                        data: card_items[i].data,
                        image: card_items[i].avatar[0],
                        heartRate: Math.round(card_items[i].lastData.heartRate),
                        calories: Math.round(card_items[i].lastData.activeEnergy),
                        score: Math.round(card_items[i].lastData.activeEnergy),
                    });
                }
            }
            return items;
        } else {
            console.log("no uid set")
        }
        return []
    }

    function resetData() {
        if (cardData.length > 0) {
            setCardData(cardData);
            setData({
                items: cardData,
                duration: 480,
                // @ts-ignore
                columnWidth: cardWidth,
                gutter: 10,
                easing: easings.quartOut,
                transition: 'fadeDown',
                rtl: false
            })
            gridRef?.updateLayout();
        }
    }

    useEffect(() => {
        uuidFromReactUUID()
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
            setWindowHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
    }, [])


    useEffect(() => {
        const area = windowHeight * windowWidth
        const cardArea = area / cardData.length
        const approxWidth = Math.floor(Math.sqrt(cardArea))
        let cardWidth = Math.min(approxWidth * 0.7, 250);
        setCardWidth(cardWidth)
    }, [windowWidth, windowHeight]);

    useEffect(() => {
        resetData()
    }, [cardWidth]);

    const transition = transitions['fadeDown'];
    return (
            <div>
                <TopBar uid={uid} gym={company}/>
                <StackGrid
                    gridRef={(grid: Grid) => {
                        setGridRef(grid)
                    }}
                    style={{margin: "10px"}}
                    duration={data?.duration}
                    columnWidth={data?.columnWidth!}
                    gutterWidth={data?.gutter}
                    gutterHeight={data?.gutter}
                    easing={data?.easing}
                    appear={transition.appear}
                    appeared={transition.appeared}
                    enter={transition.enter}
                    entered={transition.entered}
                    leaved={transition.leaved}
                    rtl={data?.rtl}
                    onLayout={() => {
                        console.log('[DEMO] `onLayout()` has been called.'); // eslint-disable-line
                    }}
                >
                    {cardData.map((item: { id: any; }) =>
                        <WorkoutCard key={item.id} item={item}/>
                    )}
                </StackGrid>
            </div>
    );
}

export default App;
