import Grid from "@mui/material/Grid";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFire, faHeartbeat} from "@fortawesome/free-solid-svg-icons";
import React, {FC} from "react";
import {Avatar,  Paper} from "@mui/material";
import {Bar, Cell, BarChart} from "recharts";

// const flexCenterStyle = {
//     display: 'flex',
//     justifyContent: 'center',
//     height: "50%",
// };

const itemStyle = {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    justifyContent: 'center',
};

// const iconStyle = {
//     color: 'red',
// };
//
// const h3Style = {
//     fontSize: '1rem',
// };

interface workoutInput {
    item: any
}

let data = [
    {name:"", value:0}
];
function generate_bars(data:any) {
    for (let item of data) {
        item.update_time = new Date(item.update_time);
    }

    const averages = [];
    const WINDOW_SIZE_MINUTES = 1;
    let currentWindow = [];
    let currentWindowStartTime = data[0].update_time;
    for(let item of data){
        let timeDifferenceMinutes = (item.update_time - currentWindowStartTime) / 1000 / 60;
        if(timeDifferenceMinutes < WINDOW_SIZE_MINUTES){
            currentWindow.push(item);
        } else {
            let average = currentWindow.reduce((acc, val) => acc + val.heartRate, 0) / currentWindow.length;
            averages.push({name:"test", value:average});
            currentWindow = [item];
            currentWindowStartTime = item.update_time;
        }
    }

    if(currentWindow.length !== 0){
        let average = currentWindow.reduce((acc, val) => acc + val.heartRate, 0) / currentWindow.length;
        averages.push({name:"test", value:average});
    }
    // console.log(averages)
    return averages
}
// Function to determine bar color based on value
const getBarColor = (value: number) => {
    if (value < 60) return '#234945';
    if (value < 90) return '#6cd04a';
    if (value < 120) return '#6295e1';
    if (value < 150) return '#f1be70';
    if (value > 150) return '#c42213';
};
const WorkoutCard: FC<workoutInput> = ({item}) => {
    data = generate_bars(item.data)
    // console.log(item)
    return (
        <Paper elevation={6}
               style={{height: item.height, borderRadius: "30px", background: getBarColor(item.heartRate)}}>
            <div key={item.id} style={{height: item.height, borderRadius: "30px"}}>
                <Grid container spacing={0} style={{backgroundColor: item.image, borderRadius: "30px", height: item.height*0.25, color: "black"}}>
                    {/*<Grid item xs={2}>*/}
                    {/*    <div style={{display: 'flex', justifyContent: 'center', alignItems: "center", height: '80%'}}>*/}
                    {/*        <Avatar style={{marginLeft: item.height*0.1, backgroundColor: "#ff6347"}}*/}
                    {/*                sx={{height: "70%"}}></Avatar>*/}
                    {/*    </div>*/}
                    {/*</Grid>*/}
                    <Grid item xs={12}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: "center", height: '80%',
                            fontSize: item.height/11}}>
                            <p>{item.name.substring(0,15)}</p>
                        </div>
                    </Grid>
                </Grid>
                <Grid container spacing={0.2} justifyContent={"center"} alignItems={"center"}>
                    <Grid item xs={12} justifyContent={"center"} alignItems={"center"}>
                        <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <BarChart
                                width={item.height - 30}
                                height={item.height*0.3}
                                data={data}
                                margin={{
                                    top: item.height*0.01, right: 5, left: 5, bottom: 5,
                                }}
                                style={{background: "white", borderRadius: "10px", marginTop: "10px"}}
                            >
                                <Bar
                                    dataKey="value"
                                    fill="#8884d8"
                                >
                                    {data.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={getBarColor(entry.value)}/>
                                    ))}
                                </Bar>
                            </BarChart>
                        </div>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <div style={{...itemStyle, marginTop: '10px'}}>
                            <FontAwesomeIcon
                                icon={faHeartbeat}
                                style={{
                                    color: 'red',
                                }}
                                height={item.height*0.1}
                                width={item.height*0.1}
                            />
                            <h3 style={{
                                fontSize: item.height/12,
                            }}>{item.heartRate}</h3>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div style={{...itemStyle, marginTop: '10px'}}>
                            <FontAwesomeIcon
                                icon={faFire}
                                style={{
                                    color: 'red',
                                }}
                                height={item.height*0.1}
                                width={item.height*0.1}
                            />
                            <h3 style={{
                                fontSize: item.height/12,
                            }}>{item.calories}</h3>
                        </div>
                    </Grid>
                </Grid>

            </div>
        </Paper>
    )
}

export default WorkoutCard;
