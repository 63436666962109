const baseUrl = "https://api-v2.fittestapp.co.uk/api"
// const baseUrl = "http://localhost:8000/api"

export async function get_cards(gym_name:string, session_id: number) {
    try{
        console.log("getting cards for session = "+ session_id)
        const data = await fetch(`${baseUrl}/sessions/old/${gym_name}/${session_id}`)
        const val= await data.json()
        console.log(val)
        return val
    }catch(e){
        console.log(e)
        return []
    }
}

export async function get_gym(gym_name: string) {
    try{
        if(gym_name){
            const data = await fetch(`${baseUrl}/gym/name/${gym_name}`)
            const val= await data.json()
            return val
        }
    }catch(e){
        console.log(e)
    }
    return {}
}
